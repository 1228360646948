import { gql } from "graphql-request";

export const LocationDataQuery = gql`
  query LocationData {
    countrydata: countrybasedinfo {
      areametricrate
      biomasscostkwheuro
      biomasscostkwhnat
      coalcostkwheuro
      coalcostkwhnat
      countryname
      countryshort
      currencyrate
      currencyshort
      dhcostkwheuro
      dhcostkwhnat
      electricitycostkwheuro
      electricitycostkwhnat
      fullloadhours
      gascostkwheuro
      gascostkwhnat
      greenrentpremiums
      nationalvatrate
      oilcostkwheuro
      oilcostkwhnat
      othercostkwheuro
      othercostkwhnat
      primeyield
      reducedvatrate
      refurbishmentborder
      vacancyratebefore
      costindexation
    }

    currencydata: currencyshort {
      currencyname
      currencyshort
    }

    climatezonedata: climatezones {
      code_climateregion
      code_country
      code_statusdataset
      heatingdays
      i_sol_east
      i_sol_hor
      i_sol_north
      i_sol_south
      i_sol_west
      i_sol_year_east
      i_sol_year_hor
      i_sol_year_north
      i_sol_year_south
      i_sol_year_west
      index_climateselectionlist
      name_climateregion
      name_climateregion_national
      shortcut_climateregion
      theta_e
      theta_e_base
      type_climatezone
    }
  }
`;

export const ConversionFactorsQuery = gql`
  query ($countryshort: String!) {
    factors: conversionfactors_by_pk(countryshort: $countryshort) {
      calorificbiomass
      calorificcoal
      calorificdistrictheating
      calorificelectricity
      calorificgas
      calorificoil
      calorificothers
      co2cfbiomass
      co2cfcoal
      co2cfdistrictheating
      co2cfelectricity
      co2cfgas
      co2cfoil
      co2cfother
      countryshort
      nrpebiomass
      nrpecoal
      nrpedistrictheating
      nrpeelectricity
      nrpegas
      nrpeoil
      nrpeother
      pebiomass
      pecoal
      pedistrictheating
      peelectricity
      pegas
      peoil
      peother
    }
  }
`;
