import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import INITIAL_STATE from "./initialstate.json";
import INITIAL_LANG from "./i18n/locale_en.json";

import { getStore } from "./store/store";

// Font
// import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
// import "@fontsource/roboto/700.css";

import "@fontsource/pt-sans";

// Styles
import "./style/bs5/bootstrap.css";
import "./style/main.scss";
import "./style/navigation.scss";
import "./style/slider.scss";

// Progress
import "react-circular-progressbar/dist/styles.css";

import preval from "preval.macro";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const buildInfo = preval`
    const os = require("os");
    module.exports = {hostname: os.hostname(), date: new Date()};
`;

const BANNER = `
 ______               __          __ ______         __
|   __ \.-----.-----.|  |_.---.-.|  |      |.---.-.|  |
|      <|  -__|     ||   _|  _  ||  |   ---||  _  ||  |
|___|__||_____|__|__||____|___._||__|______||___._||__| v2.0
Build Date: ${buildInfo.date}
Build Host: ${buildInfo.hostname}
`;

console.log(BANNER);

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);

const Loading = () => {
  return <div>Loading ....</div>;
};

/* prettier-ignore */ const Screen01 = React.lazy(() => import(/* webpackChunkName: "screen01" */ "./forms/intro/screen01"));
/* prettier-ignore */ const Screen02 = React.lazy(() => import(/* webpackChunkName: "screen02" */ "./forms/intro/screen02"));
/* prettier-ignore */ const Screen03 = React.lazy(() => import(/* webpackChunkName: "screen03" */ "./forms/intro/screen03") );
/* prettier-ignore */ const Screen04 = React.lazy(() => import(/* webpackChunkName: "screen04" */ "./forms/intro/screen04") );
/* prettier-ignore */ const Video = React.lazy(() => import(/* webpackChunkName: "video" */ "./forms/intro/video") );
/* prettier-ignore */ const Selection = React.lazy(() => import(/* webpackChunkName: "selection" */ "./forms/intro/selection") );
/* prettier-ignore */ const Project = React.lazy(() => import(/* webpackChunkName: "project" */ "./forms/project/project"));
/* prettier-ignore */ const Location = React.lazy(() => import(/* webpackChunkName: "location" */ "./forms/location/location") );
/* prettier-ignore */ const Location2 = React.lazy(() => import(/* webpackChunkName: "location2" */ "./forms/location2/location2.main") );
/* prettier-ignore */ const InvestorCharacteristics = React.lazy(() => import(/* webpackChunkName: "investorcharacteristics" */ "./forms/investorcharacteristics") );
/* prettier-ignore */ const PropertyDescription = React.lazy(() => import(/* webpackChunkName: "propertydescription" */ "./forms/propertydesc") );
/* prettier-ignore */ const APropertyDesc = React.lazy(() => import(/* webpackChunkName: "asmpropertydesc" */ "./forms/asmpropertydesc/asmpropertydesc.main"), );
/* prettier-ignore */ const InvCostsEnergeticRef = React.lazy(() => import(/* webpackChunkName: "invcostsenref" */ "./forms/invcostsenref/invcostsenref.main") );
/* prettier-ignore */ const ChangeAnnualMaintenanceAfterRef = React.lazy(() => import(/* webpackChunkName: "channualmaintafterref" */ "./forms/channualmaintafterref/channualmaintafterref") );
/* prettier-ignore */ const Depreciation = React.lazy(() => import(/* webpackChunkName: "depreciation" */ "./forms/depreciation") );
/* prettier-ignore */ const AsmAnEnergyCostsBeforeRef = React.lazy(() => import(/* webpackChunkName: "asmanenergycostsbeforeref" */ "./forms/anenergycostsbeforeref/anenergycostsbeforeref") );
/* prettier-ignore */ const AnEnergyCostsAfterRef = React.lazy(() => import(/* webpackChunkName: "anenergycostsafterref" */ "./forms/anenergycostsafterref/anenergycostsafterref") );
/* prettier-ignore */ const FinancialInformation = React.lazy(() => import(/* webpackChunkName: "fininfo" */ "./forms/fininfo/fininfo") );
/* prettier-ignore */ const FinancialInformation2 = React.lazy(() => import(/* webpackChunkName: "fininfo" */ "./forms/fininfo/fininfo2") );
/* prettier-ignore */ const MarketScenarios = React.lazy(() => import(/* webpackChunkName: "marketscenario" */ "./forms/marketscenario") );
/* prettier-ignore */ const BundleRetrofitMeasures2 = React.lazy(() => import(/* webpackChunkName: "asmbundleretrofitmeasures2" */ "./forms/asmbundleretrofitmeasures/asmbundleretrofitmeasures.main") );
/* prettier-ignore */ const RentRollDetails = React.lazy(() => import(/* webpackChunkName: "rentrolldetails" */ "./forms/rentrolldetails") );
/* prettier-ignore */ const RentIncDueRefurbishment = React.lazy(() => import(/* webpackChunkName: "rentincduerefurbishment" */ "./forms/rentincduerefurbishment") );
/* prettier-ignore */ const ConsCapitalGains = React.lazy(() => import(/* webpackChunkName: "conscapitalgains" */ "./forms/conscapitalgains") );
/* prettier-ignore */ const Disclaimer = React.lazy(() => import(/* webpackChunkName: "disclaimer" */ "./forms/disclaimer") );
/* prettier-ignore */ const VOFIGrid = React.lazy(() => import(/* webpackChunkName: "vofigrid" */ "./forms/vofigrid") );
/* prettier-ignore */ const KPISInvPersp1 = React.lazy(() => import(/* webpackChunkName: "kpisinvpersp1" */ "./forms/kpisinvpersp1") );
/* prettier-ignore */ const KPISInvPersp2 = React.lazy(() => import(/* webpackChunkName: "kpisinvpersp2" */ "./forms/kpisinvpersp2") );
/* prettier-ignore */ const KPISTenPersp = React.lazy(() => import(/* webpackChunkName: "kpitenpersp" */ "./forms/kpitenpersp") );
/* prettier-ignore */ const KPISEnvRersPersp = React.lazy(() => import(/* webpackChunkName: "kpisenvres" */ "./forms/kpisenvres") );
/* prettier-ignore */ const AddNonMonImpRef = React.lazy(() => import(/* webpackChunkName: "addnonmonimpref" */ "./forms/addnonmonimpref") );
/* prettier-ignore */ const PrintReportConfig = React.lazy(() => import(/* webpackChunkName: "printreportconfig" */ "./forms/printreportconfig") );
/* prettier-ignore */ const DetailedEstimationOfExitYield = React.lazy(() => import(/* webpackChunkName: "asmdetailedestimationofexityield" */ "./forms/asmdetailedestimationofexityield") );
/* prettier-ignore */ const Sensitivity = React.lazy(() => import(/* webpackChunkName: "sensitivity" */ "./forms/sensitivity/sensitivity") );
/* prettier-ignore */ const CombEnConsBeforeRef = React.lazy(() => import(/* webpackChunkName: "combenconsbeforeref" */ "./forms/combenconsbef/CombEnConsBeforeRef.main") );
/* prettier-ignore */ const CombEnConsAfterRef = React.lazy(() => import(/* webpackChunkName: "combenconsafterref" */ "./forms/combenconsafter/CombEnConsAfterRef.main") );

function* generate() {
  let index = 0;
  yield 0;
  while (true) {
    yield (index += 5);
  }
}

const genProgress = generate();

const boot = (initialState = INITIAL_STATE, initialLanguage = INITIAL_LANG) => {
  const state: any = {
    ...initialState,
    i18n: {
      ...initialLanguage,
    },
  };

  const { store, persistor } = getStore(state);

  const counter = [];
  for (let i = 0; i < 36; i += 3) {
    counter.push(i);
  }

  const getCat = (ct: "-" | "I" | "P" | "R" | "F" | "M") => {
    return ct;
  };

  const routerElements = [
    {
      element: <App persistor={persistor} store={store} translations={initialLanguage} />,
      id: "app",
      children: [
        // {
        //   //   path: "/",
        //   index: true,
        //   id: "welcome",
        //   element: (
        //     <React.Suspense fallback={<Loading />}>
        //       <Screen01 />
        //     </React.Suspense>
        //   ),
        //   handle: {
        //     progress: 0,
        //   },
        // },
        {
          index: true,
          //   path: "intro/1",
          id: "intro",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Screen01 />
            </React.Suspense>
          ),
          handle: {
            progress: counter[0],
            cat: getCat("-"),
          },
        },
        {
          path: "intro/2",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Screen02 />
            </React.Suspense>
          ),
          handle: {
            progress: counter[1],
            cat: getCat("-"),
          },
        },
        {
          path: "intro/3",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Screen03 />
            </React.Suspense>
          ),
          handle: {
            progress: counter[2],
            cat: getCat("-"),
          },
        },
        {
          path: "intro/4",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Screen04 />
            </React.Suspense>
          ),
          handle: {
            progress: counter[3],
            cat: getCat("-"),
          },
        },
        {
          path: "video",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Video />
            </React.Suspense>
          ),
          handle: {
            progress: counter[4],
            cat: getCat("-"),
          },
        },
        {
          path: "selection",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Selection />
            </React.Suspense>
          ),
          handle: {
            progress: counter[5],
            cat: getCat("-"),
          },
        },
        {
          path: "project",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Project />
            </React.Suspense>
          ),
          handle: {
            progress: counter[6],
            cat: getCat("I"),
          },
        },
        {
          path: "location",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Location2 />
            </React.Suspense>
          ),
          handle: {
            progress: counter[7],
            cat: getCat("I"),
          },
        },
        {
          path: "location2",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Location2 />
            </React.Suspense>
          ),
          handle: {
            progress: counter[8],
            cat: getCat("I"),
          },
        },
        {
          path: "assisted/propdesc",
          element: (
            <React.Suspense fallback={<Loading />}>
              <APropertyDesc />
            </React.Suspense>
          ),
          handle: {
            progress: counter[9],
            cat: getCat("I"),
          },
        },
        {
          path: "invchars",
          element: (
            <React.Suspense fallback={<Loading />}>
              <InvestorCharacteristics />
            </React.Suspense>
          ),
          handle: {
            progress: counter[10],
            cat: getCat("I"),
          },
        },
        {
          path: "propdesc",
          element: (
            <React.Suspense fallback={<Loading />}>
              <PropertyDescription />
            </React.Suspense>
          ),
          handle: {
            progress: counter[11],
            cat: getCat("P"),
          },
        },
        {
          path: "invcostenref",
          element: (
            <React.Suspense fallback={<Loading />}>
              <InvCostsEnergeticRef />
            </React.Suspense>
          ),
          handle: {
            progress: counter[12],
            cat: getCat("I"),
          },
        },
        {
          path: "chanmainafterref",
          element: (
            <React.Suspense fallback={<Loading />}>
              <ChangeAnnualMaintenanceAfterRef />
            </React.Suspense>
          ),
          handle: {
            progress: counter[14],
            cat: getCat("R"),
          },
        },
        {
          path: "depreciation",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Depreciation />
            </React.Suspense>
          ),
          handle: {
            progress: counter[15],
            cat: getCat("R"),
          },
        },
        {
          path: "anecafterref",
          element: (
            <React.Suspense fallback={<Loading />}>
              <AnEnergyCostsAfterRef />
            </React.Suspense>
          ),
          handle: {
            progress: counter[16],
            cat: getCat("R"),
          },
        },
        {
          path: "fininfo",
          element: (
            <React.Suspense fallback={<Loading />}>
              <FinancialInformation2 />
            </React.Suspense>
          ),
          handle: {
            progress: counter[17],
            cat: getCat("R"),
          },
        },
        {
          path: "marketscenarios",
          element: (
            <React.Suspense fallback={<Loading />}>
              <MarketScenarios />
            </React.Suspense>
          ),
          handle: {
            progress: counter[18],
            cat: getCat("M"),
          },
        },
        {
          path: "rentrolldetails",
          element: (
            <React.Suspense fallback={<Loading />}>
              <RentRollDetails />
            </React.Suspense>
          ),
          handle: {
            progress: counter[19],
            cat: getCat("M"),
          },
        },
        {
          path: "rentincdueref",
          element: (
            <React.Suspense fallback={<Loading />}>
              <RentIncDueRefurbishment />
            </React.Suspense>
          ),
          handle: {
            progress: counter[20],
            cat: getCat("F"),
          },
        },
        {
          path: "conscapitalgains",
          element: (
            <React.Suspense fallback={<Loading />}>
              <ConsCapitalGains />
            </React.Suspense>
          ),
          handle: {
            progress: counter[21],
            cat: getCat("F"),
          },
        },
        {
          path: "disclaimer",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Disclaimer />
            </React.Suspense>
          ),
          handle: {
            progress: counter[22],
            cat: getCat("F"),
          },
        },
        {
          path: "kpiinvpersp1",
          element: (
            <React.Suspense fallback={<Loading />}>
              <KPISInvPersp1 />
            </React.Suspense>
          ),
          handle: {
            progress: counter[23],
            cat: getCat("F"),
          },
        },
        {
          path: "kpiinvpersp2",
          element: (
            <React.Suspense fallback={<Loading />}>
              <KPISInvPersp2 />
            </React.Suspense>
          ),
          handle: {
            progress: counter[24],
            cat: getCat("F"),
          },
        },
        {
          path: "vofigrid",
          element: (
            <React.Suspense fallback={<Loading />}>
              <VOFIGrid />
            </React.Suspense>
          ),
          handle: {
            progress: counter[25],
            cat: getCat("F"),
          },
        },
        {
          path: "kpitenpersp",
          element: (
            <React.Suspense fallback={<Loading />}>
              <KPISTenPersp />
            </React.Suspense>
          ),
          handle: {
            progress: counter[26],
            cat: getCat("F"),
          },
        },
        {
          path: "kpisenvrerspersp",
          element: (
            <React.Suspense fallback={<Loading />}>
              <KPISEnvRersPersp />
            </React.Suspense>
          ),
          handle: {
            progress: counter[27],
            cat: getCat("F"),
          },
        },
        {
          path: "addnonmonimpref",
          element: (
            <React.Suspense fallback={<Loading />}>
              <AddNonMonImpRef />
            </React.Suspense>
          ),
          handle: {
            progress: counter[28],
            cat: getCat("R"),
          },
        },
        {
          path: "confprintrep",
          element: (
            <React.Suspense fallback={<Loading />}>
              <PrintReportConfig />
            </React.Suspense>
          ),
          handle: {
            progress: counter[29],
            cat: getCat("F"),
          },
        },
        {
          path: "detailedestimationofexityield",
          element: (
            <React.Suspense fallback={<Loading />}>
              <DetailedEstimationOfExitYield />
            </React.Suspense>
          ),
          handle: {
            progress: counter[30],
            cat: getCat("F"),
          },
        },
        {
          path: "sensitivity",
          element: (
            <React.Suspense fallback={<Loading />}>
              <Sensitivity />
            </React.Suspense>
          ),
          handle: {
            progress: counter[31],
            cat: getCat("F"),
          },
        },
        {
          path: "combensconsbeforeref/*",
          element: (
            <React.Suspense fallback={<Loading />}>
              <CombEnConsBeforeRef />
            </React.Suspense>
          ),
          handle: {
            progress: counter[32],
            cat: getCat("R"),
          },
        },
        {
          path: "asmanecbeforeref/*",
          element: (
            <React.Suspense fallback={<Loading />}>
              <AsmAnEnergyCostsBeforeRef />
            </React.Suspense>
          ),
          handle: {
            progress: counter[33],
            cat: getCat("R"),
          },
        },
        {
          path: "ecafterref/*",
          element: (
            <React.Suspense fallback={<Loading />}>
              <CombEnConsAfterRef />
            </React.Suspense>
          ),
          handle: {
            progress: counter[34],
            cat: getCat("R"),
          },
        },
        {
          path: "assisted/rfmeasures",
          element: (
            <React.Suspense fallback={<Loading />}>
              <BundleRetrofitMeasures2 />
            </React.Suspense>
          ),
          handle: {
            progress: counter[35],
            cat: getCat("R"),
          },
        },
        {
          path: "asmanecafterref/*",
          element: (
            <React.Suspense fallback={<Loading />}>
              <AnEnergyCostsAfterRef />
            </React.Suspense>
          ),
          handle: {
            progress: counter[36],
            cat: getCat("R"),
          },
        },
      ],
    },
    {
      path: "*",
      element: <h3>Invalid url</h3>,
    },
  ];

  const routes = createBrowserRouter(routerElements);

  //   root.render(<App persistor={persistor} store={store} translations={initialLanguage} />);
  root.render(<RouterProvider router={routes} />);
};

boot();
