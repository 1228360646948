import { Action, createAction, createSlice, current, Dispatch } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import Settings from "../../settings";

const initialState = {
  dataLoaded: false,
  language: "",
  convData: null,
  convDataLoaded: false,
  amortSchemes: [],
  amortSchemesLoaded: false,
  selectedAmortScheme: null,
  typeForms: [],
  cbRateFixedDisabled: true,
  // from initialstate.json
  TotalGrossCostsEnergyShare: "",
  EquityVolume: "",
  ExternalCapitalTotal: "",
  LTV: "",
  SubsidisedLoanVolume: "",
  SubsidisedLoanInterestRate: "",
  SubsidisedLoanTerm: "",
  InitialPaybackPause: "",
  RepaymentBonus: "",
  MarketLoanVolume: "",
  AmortScheme: "",
  CodeAmortScheme: "",
  BorrowingRateFixed: "",
  BorrowingRateVariable: "",
  BorrowingRateMarketLoan: "",
  BorrowingRateFixedVariable: "",
  IndividualSavingsRate: "",
  ExpectedGrantsVolume: "",
  TotalGrossCostsEnergy: "",
  dirty: false,
};

export const loadTypesformsData = function (codetypesformscombi: string) {
  return async function (dispatch: Dispatch, getState: any) {
    const url = Settings.getRESTEndpointURL() + "/typeformsdata/" + codetypesformscombi;
    const data = await fetch(url).then((data) => data.json());

    const state = getState();

    dispatch({ type: "fininfo/tf_loaded", payload: { ...data.typesforms } });

    const ltv = data.typesforms[0].ltv * 100;
    dispatch({ type: "fininfo/update_ltv", payload: ltv });

    // Set initial value and trigger calculation
    if (state.fininfo.dirty === false) {
      const value = state.machines.invcostsenergeticref.context.TotalGrossCostsEnergyShare;
      dispatch({ type: "fininfo/init_grosscosts", payload: value });
      dispatch(calcFinInfoValues() as any);
    }
  };
};

export const calcFinInfoValues = function () {
  return (dispatch: Dispatch, getState: any) => {
    //
    const state = getState();
    const totalGrossCostsEnergyShare = state.fininfo.TotalGrossCostsEnergyShare;

    if (isNaN(totalGrossCostsEnergyShare) || isNaN(state.fininfo.LTV)) {
      dispatch({ type: "fininfo/update_field", payload: { fieldname: "EquityVolume", value: "" } });
      dispatch({ type: "fininfo/update_field", payload: { fieldname: "ExternalCapitalTotal", value: "" } });
      dispatch({ type: "fininfo/update_field", payload: { fieldname: "MarketLoanVolume", value: "" } });
      return;
    }

    //
    const eqv = totalGrossCostsEnergyShare - (totalGrossCostsEnergyShare * state.fininfo.LTV) / 100;
    dispatch({ type: "fininfo/update_field", payload: { fieldname: "EquityVolume", value: eqv } });

    //
    const ecp = totalGrossCostsEnergyShare - eqv;
    dispatch({ type: "fininfo/update_field", payload: { fieldname: "ExternalCapitalTotal", value: ecp } });

    //
    const mlv = ecp - state.fininfo.SubsidisedLoanVolume;
    dispatch({ type: "fininfo/update_field", payload: { fieldname: "MarketLoanVolume", value: mlv } });
  };
};

export const loadAmortSchemeData = function () {
  return async function (dispatch: Dispatch, getState: any) {
    const url = Settings.getRESTEndpointURL() + "/amschemes";
    const data = await fetch(url).then((data) => data.json());
    dispatch({ type: "fininfo/ams_loaded", payload: data.amortschemes });
  };
};

// Handle Radio Buttons ()
export const updateTypeBorrowingRate = function (typeOfRate: "fixed" | "variable" | "") {
  return function (dispatch: Dispatch, getState: any) {
    dispatch({ type: "fininfo/update_typerate", payload: typeOfRate });
  };
};

export const updateFinInfoField = (fieldname: string, value: any) => {
  return {
    type: "fininfo/update_field",
    payload: {
      fieldname,
      value,
    },
  };
};

export const resetFinInfo = () => {
  return function (dispatch: Dispatch, getState: any) {
    const state = getState().fininfo;
    const ltv = state.typeForms[0].ltv * 100;

    const tgc = getState().machines.invcostsenergeticref.context.TotalGrossCostsEnergyShare;

    dispatch({
      type: "fininfo/reset",
      payload: {
        ...state,
        TotalGrossCostsEnergyShare: tgc,
        LTV: ltv,
        EquityVolume: "",
        ExternalCapitalTotal: "",
        SubsidisedLoanVolume: "",
        SubsidisedLoanInterestRate: "",
        SubsidisedLoanTerm: "",
        InitialPaybackPause: "",
        RepaymentBonus: "",
        MarketLoanVolume: "",
        ExpectedGrantsVolume: "",
        // combobox
        selectedAmortScheme: "",
        BorrowingRateMarketLoan: "",
        // Radio
        BorrowingRateFixedVariable: "",
        IndividualSavingsRate: "",
        dirty: false,
      },
    });

    dispatch(calcFinInfoValues() as any);
  };
};

export const finInfoSlice = createSlice({
  name: "fininfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase("fininfo/ams_loaded", (state: any, action: any) => {
      state.dataLoaded = true;
      state.amortSchemes = [...action.payload];
    });

    builder.addCase("fininfo/tf_loaded", (state: any, action: any) => {
      state.dataLoaded = true;
      state.typeForms = { ...action.payload };
    });

    builder.addCase("fininfo/update_field", (state: any, action: any) => {
      const { fieldname, value } = action.payload;
      if (fieldname === "TotalGrossCostsEnergyShare") {
        state.dirty = true;
      }
      state[fieldname] = value;
    });

    // Radio Buttons
    builder.addCase("fininfo/update_typerate", (state: any, action: any) => {
      state.BorrowingRateFixedVariable = action.payload;
    });

    builder.addCase("fininfo/reset", (state: any, action: any) => {
      return { ...action.payload };
    });

    builder.addCase("fininfo/init_grosscosts", (state: any, action: any) => {
      state.TotalGrossCostsEnergyShare = action.payload;
    });

    builder.addCase("fininfo/mark_dirty", (state: any, action: any) => {
      state.dirty = action.payload;
    });

    // ComboBox
    builder.addCase("fininfo/update_ascheme", (state: any, action: any) => {
      state.selectedAmortScheme = { ...action.payload };

      switch (state.selectedAmortScheme.codeamortscheme) {
        case 1: {
          //
          state.cbRateFixedDisabled = false;
          break;
        }
        case 2: {
          //
          state.cbRateFixedDisabled = false;
          break;
        }
        case 3: {
          //
          state.cbRateFixedDisabled = true;
          state.BorrowingRateFixedVariable = "";
          break;
        }
      }
    });

    builder.addCase("fininfo/update_ltv", (state: any, action: any) => {
      if (isEmpty(state.LTV)) {
        state.LTV = Number.parseFloat(action.payload);
      }
    });
  },
});
