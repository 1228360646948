import React, { createContext } from "react";
import { Provider } from "react-redux";
import { Root } from "./root/root";
import Modal from "react-modal";

// Styles
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Loading } from "./comps/loading";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useMatches } from "react-router";

// Create a client
const queryClient = new QueryClient();

export const StoreContext = React.createContext({});

//////////////////////////////////////////////////////////////////////////////////////////
// App / Main Shell
//////////////////////////////////////////////////////////////////////////////////////////

const UIContext = createContext({ helpText: "" });

Modal.setAppElement("#app");

export default function App(props: any) {
  return (
    <UIContext.Provider value={{ helpText: "" }}>
      <Provider store={props.store}>
        <PersistGate loading={<Loading />} persistor={props.persistor}>
          <QueryClientProvider client={queryClient}>
            <StoreContext.Provider value={{ persistor: props.persistor }}>
              <Root fallBackTranslations={props.translations} />
            </StoreContext.Provider>
            {/* <ReactQueryDevtools initialIsOpen={false} position={"top-right"} /> */}
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </UIContext.Provider>
  );
}
