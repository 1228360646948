import { configureStore, createAction, createReducer } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { createRootReducer } from "./root.reducer";

const SET_HELPCONTENT = "SET_HELPCONTENT";
const SET_SETTINGSDATA = "SET_SETTINGSDATA";
const SET_SIGNUPCONTENT = "SET_SIGNUPCONTENT";
const SET_LOCATIONDATA = "SET_LOCATIONDATA";
const SET_INVTYPEDATA = "SET_LOCATIONDATA";
const SET_UITRANSLATION = "SET_UITRANSLATION";
const SET_MENUDATA = "SET_MENUDATA";
const SET_STATE = "SET_STATE";
const SET_TERMSDATA = "SET_TERMSDATA";
const SET_SIDEBARTAB = "SET_SIDEBARTAB";
const SET_CALCRESULT = "SET_CALCRESULT";
const SET_DISPLAYRIGHTSIDEBAR = "SET_DISPLAYRIGHTSIDEBAR";
const SET_DATA = "SET_DATA";
const SET_STATUS = "SET_STATUS";
const SET_LOCATION = "SET_LOCATION";
const SET_MODE = "SET_MODE";
const SET_ACTIVE_SUB = "SET_ACTIVE_SUB";
export const SET_CHARTLAYOUT = "SET_CHARTLAYOUT";

const SET_CF_BEFORE = "SET_CF_BEFORE";
const SET_CF_AFTER = "SET_CF_AFTER";

// New Actions (mostly related to persisting the XState machines)
const SET_RC2_APROPDESC_MACHINE = "SET_RC2_APROPDESC_MACHINE";
export const UPDATE_ENCONS_AFTERREF_VIA_MEASURES = "UPDATE_ENCONS_AFTERREF_VIA_MEASURES";
export const SET_ASMENCONSBEFOREREF_MACHINE = "SET_ASMENCONSBEFOREREF_MACHINE";

export const SET_CALCSET_SYTEM_DETAILS = "SET_CALCSET_SYTEM_DETAILS";

//
export const STORE_ENCONS_BEFORE = "STORE_ENCONS_BEFORE";
export const STORE_ENCONS_AFTER = "STORE_ENCONS_AFTER";

// Save a copy of the selected country conversion factors
export const SET_COUNTRY_CONVFACTORS = "SET_COUNTRY_CONVFACTORS";

// v2
export const PREFILL_CONV_FACTORS = "PREFILL_CONV_FACTORS";
export const UPDATE_LETTABLE_AREA = "UPDATE_LETTABLE_AREA";
export const INIT_ENCONS_AFTER = "INIT_ENCONS_AFTER";

export const SET_ANENCOSTSBEFOREREF_MACHINE = "SET_ANENCOSTSBEFOREREF_MACHINE";
export const SET_ANENCOSTSAFTERREF_MACHINE = "SET_ANENCOSTSAFTERREF_MACHINE";
export const SET_INVCHARS_MACHINE = "SET_INVCHARS_MACHINE";
export const SET_ABUNDLERETROFITMEASURES_MACHINE = "SET_ABUNDLERETROFITMEASURES_MACHINE";
export const SET_INVCOSTENREF_MACHINE = "SET_INVCOSTENREF_MACHINE";

export const UPDATE_LOCATION_MACHINE = "UPDATE_LOCATION_MACHINE";

const initialState = {};

// New (01.08.2022)
// Persist the machine state

// This is the "global" state reducer for the "v2" version
// Machine enhanced version shoud store their "context" into
// the "v2" state tree (see initialstate.json)

export function getStore(loadedState: any) {
  const store = configureStore({
    // -- Reducers --
    reducer: createRootReducer(loadedState),
    // -- Middlewares --
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        serializableCheck1: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, "SET_RC2_APROPDESC_MACHINE"],
        },
      }).concat(
        createLogger({
          collapsed: true,
          timestamp: false,
        })
      ),
    // -- Misc --
    devTools: process.env.NODE_ENV !== "production",
    preloadedState: loadedState,
  });

  const persistor = persistStore(store);
  return { store, persistor };
}

export const setData = createAction<Record<string, any>>("SET_DATA");
export const setMode = createAction<string>("SET_MODE");
export const setHelpKey = createAction<string>("SET_HELPKEY");
export const setSignupContent = createAction<Record<string, any>>("SET_SIGNUPCONTENT");
export const setLocationData = createAction<Record<string, any>>("SET_LOCATIONDATA");
export const setInvTypeData = createAction<Record<string, any>>("SET_INVTYPEDATA");
export const setMenuData = createAction<Record<string, any>>("SET_MENUDATA");
export const setTermsData = createAction<boolean>("SET_TERMSDATA");
export const setSettingsData = createAction<Record<string, any>>("SET_SETTINGSDATA");

export const setTranslationLanguage = createAction<any>("SET_UITRANSLATION");

export const setRightSidebar = createAction<Record<string, any>>("SET_DISPLAYRIGHTSIDEBAR");
//export const setCalcResult = createAction<Record<string, any>>("SET_CALCRESULT");
export const setStatus = createAction<Record<string, any>>("SET_STATUS");
export const setLocation = createAction<Record<string, any>>("SET_LOCATION");
export const setSubState = createAction<Record<string, any>>("SET_ACTIVE_SUB");
export const setSidebarTab = createAction<number>("SET_SIDEBARTAB");
export const setProgress = createAction<number>("SET_PROGRESS");
