export const UIReducer = (state = {}, action: any) => {
  let tempState = null;

  switch (action.type) {
    case "SET_TERMSDATA":
      tempState = Object.assign({}, state, action.payload);
      return tempState;
    case "SET_HELPKEY":
      tempState = Object.assign({}, state, {
        activetab: 1,
        helpkey: action.payload,
      });
      return tempState;
    case "SET_SIDEBARTAB":
      tempState = Object.assign({}, state, { activetab: action.payload });
      // activetab
      return tempState;
    case "SET_PROGRESS":
      tempState = Object.assign({}, state, { progress: action.payload });
      return tempState;
    case "SET_MENUDATA":
      tempState = Object.assign({}, state, action.payload);
      return tempState;
    case "SET_DISPLAYRIGHTSIDEBAR":
      tempState = Object.assign({}, state, action.payload);
      return tempState;
    case "SET_LOCATION":
      tempState = Object.assign({}, state, action.payload);
      return tempState;
    case "SET_MODE":
      tempState = Object.assign({}, state, action.payload);
      return tempState;
    case "SET_CHARTLAYOUT": {
      const _state = {
        ...state,
        chartlayout: action.layout,
      };
      return _state;
    }
    case "SET_STATE":
      // Set state - "empty" / "work"
      tempState = Object.assign({}, state, action.payload);
      return tempState;
    default:
      return state;
  }
};
