export const SIDEBAR_MENU = {
  "sidebartab.label.l0.information": [
    {
      label: "sidebartab.label.l0.information.l1.termsofservice",
      url: "/intro/2/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.information.l1.contributors",
      url: "/intro/3/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.information.l1.toolstructure",
      url: "/intro/4/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.information.l1.videotutorial",
      url: "/video/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.information.l1.modeselection",
      url: "/selection/",
      stepcat: 0,
    },
  ],
  "sidebartab.label.l0.input": [
    {
      label: "sidebartab.label.l0.input.l1.investor.l2.projectdata",
      url: "/project/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.investor.l2.locationdata",
      url: "/location/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.investor.l2.investordata",
      url: "/invchars/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.property.l2.propertydescription",
      url: "/assisted/propdesc/",
      stepcat: 2,
    },
    {
      label: "sidebartab.label.l0.input.l1.property.l2.energyconsumption",
      url: "/combensconsbeforeref/calc/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.property.l2.conversionfactors",
      url: "/combensconsbeforeref/calc/factor/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.property.l2.energycosts",
      url: "/asmanecbeforeref/calc/",
      stepcat: 0,
    },
    {
      label: "assmode.bundleretrofit.measure.headline",
      url: "/assisted/rfmeasures/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.property.l2.detailedenergyprices",
      url: "/asmanecbeforeref/prices/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.refurbishment.l2.costsummary",
      url: "/invcostenref/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.refurbishment.l2.detailedmeasurecosts",
      url: "xxxx",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.refurbishment.l2.maintenancecosts",
      url: "/chanmainafterref/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.refurbishment.l2.depreciation",
      url: "/depreciation/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.refurbishment.l2.energyconsumption",
      url: "/ecafterref/calc/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.refurbishment.l2.conversionfactors",
      url: "/ecafterref/factor/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.refurbishment.l2.energycosts",
      url: "/asmanecafterref/calc/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.refurbishment.l2.detailedenergyprices",
      url: "/asmanecafterref/prices/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.financing.l2.financialinformation",
      url: "/fininfo/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.market.l2.pricedevelopment",
      url: "/marketscenarios/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.market.l2.tenancysituation",
      url: "/rentrolldetails/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.market.l2.detailedtenancyinput",
      url: "xxxx",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.market.l2.rentincreasemethod",
      url: "/rentincdueref/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.market.l2.exityield",
      url: "/conscapitalgains/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.input.l1.market.l2.exityieldcalculation",
      url: "xxxx",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.output.l1.disclaimer",
      url: "/disclaimer/",
      stepcat: 0,
    },
  ],
  "sidebartab.label.l0.output": [
    {
      label: "sidebartab.label.l0.output.l1.calculationresults",
      url: "/kpiinvpersp1/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.output.l1.calculationresults.allkpis",
      url: "/kpiinvpersp2/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.output.l1.calculationresults.vofi",
      url: "/vofigrid/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.output.l1.calculationresults.tenantperspective",
      url: "/kpitenpersp/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.output.l1.calculationresults.environmentaleffects",
      url: "/kpisenvrerspersp/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.output.l1.calculationresults.nonmonetaryeffects",
      url: "/addnonmonimpref/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.output.l1.printrep",
      url: "/confprintrep/",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.output.l1.calculationresults.runningcosts",
      url: "xxxx",
      stepcat: 0,
    },
    {
      label: "sidebartab.label.l0.output.l1.sensitivityanalysis",
      url: "/sensitivity/",
      stepcat: 0,
    },
  ],
};
