import React, { ReactPropTypes } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import classNames from "classnames";
import { setHelpKey, setSidebarTab, setStatus } from "../store/store";
import Settings from "../settings";

export default function HelpTrigger(props: any) {
  const dispatch = useDispatch();

  if (!props.id || props.id === "") {
    console.info("%c ≡≡≡ Missing HelpTrigger ID ≡≡≡", "background-color: #f00000; color: #fff");
  }

  return (
    <>
      <div id={props.id} className={classNames("btnhelp", props.classes)}>
        <img
          src={`${Settings.assetURL()}/icons/help.svg`}
          width="20"
          height="20"
          onClick={() => dispatch(setHelpKey(props.id))}
          onFocus={() => dispatch(setHelpKey(props.id))}
          onMouseOver={() => dispatch(setHelpKey(props.id))}
          onMouseOut={() => dispatch(setHelpKey(props.id))}
          onBlur={() => dispatch(setHelpKey(props.id))}
        />
      </div>
    </>
  );
}

// // TODO fix broken help display
// class HelpTrigger1 extends React.Component<any, any> {
//   constructor(props: any) {
//     super(props);
//     this.state = {
//       isHelpContentLocked: false,
//     };
//     this.onHelpClick = this.onHelpClick.bind(this);
//     this.onHelpMouseOver = this.onHelpMouseOver.bind(this);
//     this.clearHelpContent = this.clearHelpContent.bind(this);
//   }

//   public componentWillUnmount() {
//     this.setState({ isHelpContentLocked: false });
//     this.clearHelpContent();
//   }

//   public onHelpClick() {
//     this.setState({ isHelpContentLocked: true });
//     this.props.dispatch(this.props.setHelpContent({ helptext: this.props.children }));
//     this.props.dispatch(setSidebarTab(1));
//   }

//   public onHelpMouseOver() {
//     this.props.dispatch(this.props.setHelpContent({ helptext: this.props.children }));
//     this.props.dispatch(setSidebarTab(1));
//   }

//   public clearHelpContent() {
//     if (!this.state.isHelpContentLocked) {
//       this.props.setHelpContent([]);
//     }
//   }

//   public render() {
//     return (
//       <>
//         <div id={this.props.id} className={classNames("btnhelp", this.props.classes)}>
//           <img
//             src={`${Settings.assetURL()}/icons/help.svg`}
//             width="20"
//             height="20"
//             onClick={this.onHelpClick}
//             onFocus={this.onHelpMouseOver}
//             onMouseOver={this.onHelpMouseOver}
//             onMouseOut={this.clearHelpContent}
//             onBlur={this.clearHelpContent}
//           />
//           <div className="helpContent" />
//         </div>
//       </>
//     );
//   }
// }

// export default connect<any, any, any, any>(
//   (state) => ({
//     activetab: state.ui.activetab,
//   }),
//   (dispatch: any) => {
//     return {
//       dispatch,
//       setStatus: bindActionCreators(setStatus, dispatch),
//       setHelpContent: bindActionCreators(setHelpContent, dispatch),
//     };
//   }
// )(HelpTrigger);
