export const DataReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "SET_DATA": {
      const tempState = { ...state, ...action.object };
      return tempState;
    }
    case "SET_COUNTRY_CONVFACTORS": {
      return {
        ...state,
        countryconversionfactors: {
          ...action.payload,
        },
      };
    }
    case "SET_CALCSET_SYTEM_DETAILS": {
      return {
        ...state,
        calcsetSystemDetails: {
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
