export const SubStateReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "SET_ACTIVE_SUB": {
      const newState = { ...state, ...action.object };
      return newState;
    }
    default: {
      return state;
    }
  }
};
