import classNames from "classnames";
import { useCallback, useMemo } from "react";

import Translate from "../translate";

import classnames from "classnames";
import { get, isEmpty, last } from "lodash";
import { ArrowRightCircle, CheckCircle, Circle } from "react-feather";
import { useMatches } from "react-router";
import { Link } from "react-router-dom";

// import MENUITEMS from "./menu.json";
import { SIDEBAR_MENU as MENUITEMS } from "./menu";

const menuItem = {
  color1: "rgb(100,116,139)",
  fontWeight: "600",
  fontFamily:
    'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
};

const subMenuItem = {
  color: "rgb(100,116,139)",
  fontWeight: "400",
  fontFamily:
    'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
};

const matchURI = (a: string, b: string) => {
  if (a.endsWith("/") && a.length > 1) {
    a = a.substring(0, a.length - 1);
  }
  if (b.endsWith("/") && b.length > 1) {
    b = b.substring(0, b.length - 1);
  }
  return a === b;
};

const MI = (props: any) => {
  return (
    <div
      className={classNames("d-flex flex-column", {
        navact: props.status === "current",
        navinact: props.status !== "current",
      })}
    >
      <div className={classNames("d-flex flex-row pt-2 pb-2", { navcurrent: props.status == "current" })}>
        <span className="pe-3 ps-4" style={{}}>
          {props.status == "done" && <CheckCircle size={14} />}
          {props.status == "todo" && <Circle size={12} />}
          {props.status == "current" && <ArrowRightCircle size={14} />}
        </span>
        <span style={{ ...menuItem }}>
          <Translate id={props.id} />
        </span>
      </div>

      {props.childs.map((item: any, i: any) => {
        return (
          props.status === "current" && (
            <Link key={i} to={item.url} className="" style={{ textDecoration: "none", color: "#666" }}>
              <div
                className={classnames("d-flex flex-row ps-5 pt-2 pb-2 align-items-center", {
                  mihi: matchURI(item.url, props.currentURL),
                })}
                style={{ ...subMenuItem }}
              >
                {matchURI(item.url, props.currentURL) && (
                  <ArrowRightCircle size="14" className="me-3" stroke="#1f93f0" />
                )}
                {!matchURI(item.url, props.currentURL) && <Circle size="14" className="me-3" stroke="#c0c0c0" />}
                <span style={{ fontWeight: item.url === props.currentURL ? "700" : "400", fontSize: "12px" }}>
                  <Translate id={item.label} />
                </span>
              </div>
            </Link>
          )
        );
      })}
    </div>
  );
};

export default function SidebarMenu(props: any) {
  const matches = useMatches();

  const url = useMemo(() => {
    if (!isEmpty(matches)) {
      return get(last(matches), "pathname", "");
    } else {
      return "";
    }
  }, [matches]);

  const menu = useCallback(() => {
    let url = "";
    if (!isEmpty(matches)) {
      url = get(last(matches), "pathname", "");
    }

    const menuitems: any[] = [];

    // Toplevel items ...
    Object.keys(MENUITEMS).forEach((item, i) => {
      let found = false;
      (MENUITEMS as any)[item].forEach((child: any, j: any) => {
        if (matchURI(child.url, url)) {
          found = true;
        }
      });
      //
      if (found) {
        menuitems.push(<MI status={"current"} id={item} currentURL={url} key={i} childs={(MENUITEMS as any)[item]} />);
      } else {
        menuitems.push(<MI status={"todo"} id={item} currentURL={url} key={i} childs={(MENUITEMS as any)[item]} />);
      }
    });
    return menuitems;
  }, [url]);

  return (
    <div style={{ overflowY: "hidden", backgroundColor: "rgb(248, 247, 247)", height: "100%", padding: "0px 0 0 0px" }}>
      {menu().map((item: any) => item)}
    </div>
  );
}
