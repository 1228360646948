import { createAsyncThunk, createSlice, Dispatch } from "@reduxjs/toolkit";
import { request } from "graphql-request";
import Settings from "../../settings";
import { ConversionFactorsQuery, LocationDataQuery } from "./location2.queries";

const initialState: any = {
  status: "pendingInitial",
  data: undefined,
  convfactors: undefined,
  selectedCountry: "",
  countryClimatezones: [],
  selectedClimatezone: null,
  selectedCurrency: "",
  areaMetric: "m2",
  checklist: {
    countrySelected: false,
    climateSelected: true,
    currencySelected: false,
  },
};

export const loadConvFactors = createAsyncThunk(
  "location/fetchconvfactors",
  async (countryshort: string, thunkAPI) => {
    return request(Settings.getGQLEndPointURL(), ConversionFactorsQuery, {
      countryshort,
    }).then((data) => thunkAPI.fulfillWithValue(data));
  },
  {}
);

export const loadInitialDataAction = createAsyncThunk(
  "location/fetchInitialData",
  async (_, thunkAPI) =>
    request(Settings.getGQLEndPointURL(), LocationDataQuery).then((data) => thunkAPI.fulfillWithValue(data)),
  {}
);

export const selectCountry = (selectedCountry: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    const state = getState();

    // TODO
    // Convert the clearing of settings to a self-contained action

    // Clear previous settings
    // state.location.selectedClimatezone = null;

    dispatch({ type: "location/setcountry", payload: selectedCountry });

    // Find country specific climatezone
    // state.location.countryClimatezones = state.location.data.climatezonedata.filter((item: any) => {
    //   return item && item.code_country == selectedCountry.countryshort;
    // });

    // // ...
    // if (state.location.countryClimatezones && state.location.countryClimatezones.length === 1) {
    //   // if there's only one entry - select the first option
    //   state.location.selectedClimatezone = state.location.countryClimatezones;
    //   state.location.checklist.climateSelected = true;
    // }

    // state.location.disableClimatezone = false;

    // Load conversion factor data
    const data = await request(Settings.getGQLEndPointURL(), ConversionFactorsQuery, {
      countryshort: selectedCountry.countryshort,
    });

    dispatch({ type: "location/convfactors", payload: data });
  };
};

export const location2Slice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadInitialDataAction.fulfilled, (state, action) => {
      state.data = { ...(action.payload as any) };
      state.status = "idle";
    });

    builder.addCase("location/convfactors", (state, action: any) => {
      state.factors = { ...action.payload.factors };
    });

    builder.addCase("location/setcurrency", (state, action: any) => {
      state.selectedCurrency = { ...action.payload };
      state.checklist.currencySelected = true;
    });

    builder.addCase("location/setcountry", (state, action: any) => {
      state.selectedCountry = { ...action.payload };
      state.checklist.countrySelected = true;
    });

    builder.addCase("location/setareametric", (state, action: any) => {
      state.areaMetric = action.payload;
    });

    // builder.addCase("location/setclimatezone", (state, action: any) => {
    //   state.selectedClimatezone = { ...action.payload };
    //   state.checklist.climateSelected = true;
    // });

    builder.addCase("location/reset", (state, action: any) => {
      state.selectedCountry = null;
      state.selectedCurrency = null;
      //   state.selectedClimatezone = null;
      state.areaMetric = "m2";
      //   state.disableClimatezone = true;
      state.countryClimatezones = [];
      state.checklist = {
        countrySelected: false,
        climateSelected: true,
        currencySelected: false,
      };
    });
  },
});
