export const MachineReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "SET_RC2_APROPDESC_MACHINE": {
      return {
        ...state,
        asmPropertyDesc: action.payload,
      };
    }
    case "SET_ASMENCONSBEFOREREF_MACHINE": {
      return {
        ...state,
        asmenconsbeforeref: {
          ...action.payload,
        },
      };
    }
    case "SET_INVCHARS_MACHINE": {
      return {
        ...state,
        invchars: {
          ...action.payload,
        },
      };
    }
    case "SET_ABUNDLERETROFITMEASURES_MACHINE": {
      return {
        ...state,
        asmbundleretrofitmeasures: {
          ...action.payload,
        },
      };
    }
    case "SET_INVCOSTENREF_MACHINE": {
      return {
        ...state,
        invcostsenergeticref: {
          ...action.payload,
        },
      };
    }
    case "UPDATE_LOCATION_MACHINE": {
      const newState = {
        ...state,
        location: {
          ...action.payload,
        },
      };
      return newState;
    }
  }
  return state;
};
