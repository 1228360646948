export const CalcResultReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "SET_CALCRESULT": {
      const tempState = { ...state, ...action.payload };
      return tempState;
    }
    default: {
      return state;
    }
  }
};
