import { combineReducers, createReducer } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { finInfoSlice } from "../forms/fininfo/fininfo.slice";
import { location2Slice } from "../forms/location2/location2.slice";
import { combineForms } from "../lib/react-redux-form";
import { CalcResultReducer } from "./reducers/calc.reducer";
import { DataReducer } from "./reducers/data.reducer";
import { I18NReducer } from "./reducers/i18n.reducer";
import { MachineReducer } from "./reducers/machine.reducer";
import { StatusReducer } from "./reducers/status.reducer";
import { SubStateReducer } from "./reducers/substate.reducer";
import { UIReducer } from "./reducers/ui.reducer";
import { V2Reducer } from "./reducers/v2.reducer";

const initialState = {
  EMailAddress: "",
  UserName: "",
  InvestorName: "",
  ObjectName: "",
  ObjectAddress: "",
  ProjectNumber: "",
  ProjectVersion: "",
};

export const projectDataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("UPDATE_PROJECTDATA", (state, action: any) => {
      state = action.payload;
      return state;
    })
    .addCase("RESET_PROJECTDATA", (state, action: any) => {
      state = initialState;
      return state;
    })
    .addDefaultCase((state, action) => {});
});

export const createRootReducer = (restoredState: any) => {
  console.log("RESTATE: %o", restoredState.pages.Depreciation);
  const reducers = combineReducers({
    ui: UIReducer,
    i18n: I18NReducer,
    calcResult: CalcResultReducer,
    substate: SubStateReducer,
    data: DataReducer,
    status: StatusReducer,
    // New jca - 01.08.2022
    machines: MachineReducer,
    /* Conversion factors */
    cf: SubStateReducer,
    v2: V2Reducer,
    projectdata: projectDataReducer,
    fininfo: finInfoSlice.reducer,
    location: location2Slice.reducer,
    // combenconsbeforeref: cecbeforeslice.reducer,
    pages: combineForms(
      {
        //ProjectData: restoredState.pages.ProjectData,
        AssModePropertyDesc: restoredState.pages.AssModePropertyDesc,
        BundleRetrofitMeasures: restoredState.pages.BundleRetrofitMeasures,
        // Location: restoredState.pages.Location,
        InvestorCharacteristics: restoredState.pages.InvestorCharacteristics,
        // PropertyDescription: restoredState.pages.PropertyDescription,
        // EnConsBeforeRef: restoredState.pages.EnConsBeforeRef,
        // AnEnergyCostsBeforeRef: restoredState.pages.AnEnergyCostsBeforeRef,
        // DetailedConvFactorsBefore: restoredState.pages.DetailedConvFactorsBefore,
        // DetailedConvFactorsAfter: restoredState.pages.DetailedConvFactorsAfter,
        ChangeAnnualMaintenanceAfterRef: restoredState.pages.ChangeAnnualMaintenanceAfterRef,
        Depreciation: restoredState.pages.Depreciation,
        // EnConsAfterRef: restoredState.pages.EnConsAfterRef,
        // AnEnergyCostsAfterRef: restoredstate.pages.AnEnergyCostsAfterRef,
        FinancialInformation: restoredState.pages.FinancialInformation,
        MarketScenarios: restoredState.pages.MarketScenarios,
        RentRollDetails: restoredState.pages.RentRollDetails,
        RentIncDueRefurbishment: restoredState.pages.RentIncDueRefurbishment,
        ConsCapitalGains: restoredState.pages.ConsCapitalGains,
        DetailedEstimationOfExitYield: restoredState.pages.DetailedEstimationOfExitYield,
        KPIInvPersp1: restoredState.pages.KPIInvPersp1,
        KPIInvPersp2: restoredState.pages.KPIInvPersp2,
        KPITenPersp: restoredState.pages.KPITenPersp,
        KPIEnvResPersp: restoredState.pages.KPIEnvResPersp,
        DetailedEnergyPricesBefore: restoredState.pages.DetailedEnergyPricesBefore,
        DetailedEnergyPricesAfter: restoredState.pages.DetailedEnergyPricesAfter,
      },
      "pages"
    ),
  });

  const persistConfig = {
    key: "root",
    keyPrefix: "",
    storage,
    blacklist: ["i18n", "forms", "ui.helptext"],
    debug: true,
  };

  const rootReducer = persistReducer(persistConfig, reducers);
  return rootReducer;
};
