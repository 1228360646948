import React from "react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../sidebar";
import { Header } from "../header";

export function Root(props: any) {
  return (
    <div id="main">
      <Header fallBackTranslations={props.fallBackTranslations} />
      <div id="root">
        <>
          <Sidebar />
          <div id="content">
            <Outlet />
          </div>
          <div id="sidebarright" className="rightsidebar"></div>
        </>
      </div>
      <div id="footer">
        <div id="footerleft">&copy; The RentalCal Consortium 2017-2022</div>
        <div id="footermiddle">
          <div id="modalApp" className="modalbox" />
        </div>
        <div id="footerright" className="d-flex flex-row align-items-center">
          <a href="https://eu.eu" target="_blank" rel="noreferrer">
            <span>2015-2018 funded by the European Union</span>
          </a>
          <a href="https://eu.eu" target="_blank" rel="noreferrer">
            <img
              src="/assets/eu_flag_mono@2x.png"
              id="europeflagxs"
              alt="Europe Flag"
              onMouseOver={(e) => {
                e.currentTarget.src = "/assets/eu_flag_color@2x.png";
              }}
              onMouseOut={(e) => {
                e.currentTarget.src = "/assets/eu_flag_mono@2x.png";
              }}
            />
          </a>
          <span className="div"></span>
          <span>
            <a href="https://www.zukunftbau.de/" target="_blank" rel="noreferrer">
              2020-2022 funded by Zukunft Bau
            </a>
          </span>
          <a href="https://www.zukunftbau.de/" target="_blank" rel="noreferrer" className="me-5">
            <img
              src="/assets/zb_logo_mono@2x.png"
              onMouseOver={(e) => {
                e.currentTarget.src = "/assets/zb_logo_color@2x.png";
              }}
              onMouseOut={(e) => {
                e.currentTarget.src = "/assets/zb_logo_mono@2x.png";
              }}
              className="mr-5"
              id="zblogo"
              alt="ZukunftBau Logo"
              width="127"
              height="21"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
