export const I18NReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "SET_UITRANSLATION": {
      console.log("%cTHE STATE: %o", "color: red", state);
      const newState = { ...state, ...action.payload };
      return newState;
    }
  }
  return state;
};
