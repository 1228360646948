import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { has as _has } from "lodash";
import Translate from "../translate";

export default function HelpTab() {
  const { activetab, helpkey } = useSelector((state: Record<string, any>) => state.ui);

  return (
    <div className={classNames("tab-pane", { tabactive: activetab === 1 })} id="help" role="tabpanel">
      <Translate id={helpkey} />
    </div>
  );
}
