import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import HelpTab from "./helptab";
import Progress from "./progress";
import Translate from "../translate";
import { setSidebarTab } from "../store/store";
import { useMatches } from "react-router";
import { get, isEmpty, last, tail } from "lodash";

export default function Sidebar(props: any) {
  const activeTab = useSelector((state: any) => state.ui.activetab);
  const dispatch = useDispatch();
  const matches = useMatches();

  const onTabSelect = useCallback((e: any) => {
    e.preventDefault();

    if (e.currentTarget.id === "tab1") {
      dispatch(setSidebarTab(0));
    } else {
      dispatch(setSidebarTab(1));
    }
  }, []);

  const progress = useMemo(() => {
    if (!isEmpty(matches)) {
      return get(last(matches), "handle.progress", 0);
    } else {
      return 0;
    }
  }, [matches]);

  return (
    <div id="sidebarleft" style={{ overflow: "hidden" }}>
      <ul className="rctabs">
        <li
          className={classNames({ active: activeTab === 0 })}
          id="tab1"
          onClick={onTabSelect}
          style={{ paddingLeft: "18px", fontSize: "14px" }}
        >
          <Translate id="sidebar.tabheader.whereami" />
        </li>
        <li
          className={classNames({ active: activeTab === 1 })}
          id="tab2"
          onClick={onTabSelect}
          style={{ fontSize: "14px" }}
        >
          <Translate id="sidebar.tabheader.help" />
        </li>
      </ul>
      <Progress width={160} height={160} progress={progress} />
      <HelpTab />
    </div>
  );
}
