export function V2Reducer(state = {}, action: any) {
  switch (action.type) {
    case "STORE_ENCONS_BEFORE": {
      const _state = {
        ...state,
        enconsbeforeref: {
          ...action.payload,
        },
      };
      return _state;
    }
    /*
    This action gets called in "Bundle of retrofit measures"
    It pre-populates the AsmEnConsAfterRef EC values.
    We save it in 2 (enconsafterrefdata + enconsafterref) places
    for the "reset" functionality
     */
    case "INIT_ENCONS_AFTER": {
      const _state = {
        ...state,
        enconsafterrefdata: {
          ...action.payload,
        },
      };
      return _state;
    }
    case "STORE_ENCONS_AFTER": {
      const _state = {
        ...state,
        enconsafterref: {
          ...action.payload,
        },
      };
      return _state;
    }
    case "PREFILL_CONV_FACTORS": {
      const _state = {
        ...state,
        enconsbeforeref: {
          ...(state as any).enconsbeforeref,
          ...action.payload,
        },
      };
      return _state;
    }
    case "UPDATE_LETTABLE_AREA": {
      const _state = {
        ...state,
        enconsbeforeref: {
          ...(state as any).enconsbeforeref,
          ...action.payload,
        },
      };
      return _state;
    }
    case "SET_ANENCOSTSBEFOREREF_MACHINE": {
      return {
        ...state,
        anenpricesbeforeref: {
          ...action.payload,
        },
      };
    }
    case "SET_ANENCOSTSAFTERREF_MACHINE": {
      return {
        ...state,
        anenpricesafterref: {
          ...action.payload,
        },
      };
    }
    default: {
      break;
    }
  }
  return state;
}
